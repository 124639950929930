import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { SERVER_ROOT } from 'env';

export const usePersonStore = create((set, get) => {
    return {
        //res.send({person, activities, userCanvasMapping, type: 'PRIVATE'})
        person: null, 
        activities: [], 
        keyword: null,
        userCanvasMapping: null, 
        type: null, 
        loading: false, 
        hasMore: false, 
        index: 0,

        loadPerson: async (personName, onlyPerson) => {
            const store = get();
            if(store.person?.username === personName&&!store.keyword) return;
            set({ loading: true, activities: [], keyword: null });
            const data = await fetch(SERVER_ROOT + `/api/activity/init?personName=${personName}&keywordName=${null}&onlyPerson=${onlyPerson}`, { credentials: 'include', method: 'GET', headers: { 'Content-Type': 'application/json'} }).then(res => res.json());
            const { person, activities, userCanvasMapping, type } = data;
            set({ person, activities, userCanvasMapping, type, loading: false, index: 0});
        },

        // addActivities: (activity) => set((state) => ({ activities: [...state.activities, activity] })),
        canvasJson: { canvas: null, isOwner: false },
        loadCanvas: async (personName, keyword) => {
            console.log('loadCanvas executed', personName, keyword);
            //check if canvas is already loadedx
            const store = get();
            if(store.person&&store.keyword == keyword) return;
            if(!store.person) await store.loadPerson(personName, true);
            const data = await fetch(SERVER_ROOT + `/api/canvas/load?person_name=${personName}&canvas_name=${keyword}`, { method: 'GET', credentials: 'include' }).then(res => res.json());
            console.log('data is', data);
            set({ canvasJson: data, activities: data.canvas.activities, keyword, canvasLoading: false });
        },   
        deleteActivity: (activity) => {
            fetch(SERVER_ROOT + `/api/activity?activityId=${activity._id}`, { method: 'DELETE', credentials: 'include',  }).then(res => res.json());
            const store = get();
            const newActivities = store.activities.filter(a => a._id !== activity._id);
            set({ activities: newActivities });
            if(activity.action.hashtags.length > 0){
                const canvasActivities = store.canvasJson.canvas.activities.filter(a => a._id !== activity._id);
                set({ canvasJson: { ...store.canvasJson, canvas: { ...store.canvasJson.canvas, activities: canvasActivities } } });
            }
        },
    };
});

export const useUserStore = create((set) => {
});

export const useSearchStore = create((set) => {
});

export const useNewsfeedStore = create((set) => {
})

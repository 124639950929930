/* global chrome */
import React, { useState, useEffect, useRef } from 'react';

import clsx from 'clsx';

import { Avatar, Typography, Button, CircularProgress, IconButton, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, Tooltip, ButtonBase, Card, Paper, CardActionArea, Divider, Skeleton, Autocomplete, Chip, TextField, Zoom } from '@mui/material';
import ToggleButton from '@mui/lab/ToggleButton';
import { red, grey } from '@mui/material/colors';

import { Clear, RateReview, Favorite, Create, ChatBubble, People, Done, Bookmark } from '@mui/icons-material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import FileOpenIcon from '@mui/icons-material/FileOpen';

import SimpleContent from 'components/simpleContent';
import Chips from './chips';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from "react-router-dom";

import SignIn from 'components/home/signIn';
import Extension from 'components/extension';

import Masonry from "@mui/lab/Masonry";

import moment from 'moment';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { usePersonStore } from 'stores';

const Activity = props => {

	//const { entity, contents, hasMore, pageNum, loadedTime } = useSelector(state => state.search); 
  const { root, appType, extensionInstalled, extensionId } = useSelector(state => state.api);
	//const entityStrings = user? user.newsfeed.entityStrings : []; 
  const [ pageNum, setPageNum ] = useState(0);
  const [ input, setInput ] = useState(null);
  const [ loading, setLoading ] = useState(null);
  const [ isEditFormClicked, setIsEditFormClicked ] = useState(false);
  const [ isBookmarkLayout, setIsBookmarkLayout ] = useState(true);
  const [ isOptionOpen, setIsOptionOpen ] = useState(false);
  const [ isScrolledDown, setIsScrolledDown ] = useState(false);

  
  const personStore = usePersonStore();
  const { loadPerson, loadCanvas } = personStore;

  const user = useSelector(state => state.user);
  const { person } = personStore
  const { activities, type, hasMore, loadedTime, userCanvasMapping } = personStore;

  //dialog
  const [ isDialogLoading, setIsDialogLoading ] = useState(false)
  const [ isOpen, setIsOpen ] = useState(false);
  const [ dataType, setDataType ] = useState(null);
  const [ datas, setDatas ] = useState([]);

	const dispatch = useDispatch();
  const navigate = useNavigate();

  const scrollRef = useRef(null);
  const params = useParams();
  const location = useLocation();

	//const isAdded = entityStrings.includes(entity.name);
  const changeLayout = e => {
    setIsBookmarkLayout(!isBookmarkLayout);
  }

  const handleDoneClick= e => {
    alert('Hide will be activated soon')
  }

  const handleLoadMore = () => {
    console.log('handleLoadMore is functioning');
    dispatch({ type: 'MORE_ACTIVITY' })
  }

  const handleLoadFollowers = () => {
    if(type==='NO_ACCESS') return;
    setIsDialogLoading(true);
    setIsOpen(true)
    setDataType('Followers')
    fetch(root + `/api/followers?person_id=${person._id}`, { credentials: 'include' })
      .then(res => {
        return res.json();
      })
      .then(json => {
        console.log('json is', json)
        setIsDialogLoading(false);
        setDatas(json.followers)
      })
      .catch(err => console.log(err))
  }

  const handleLoadFollowings = () => {
    if(type==='NO_ACCESS') return;
    setIsDialogLoading(true);
    setIsOpen(true)
    setDataType('Followings')
    fetch(root + `/api/followings?person_id=${person._id}`, { credentials: 'include' })
      .then(res => {
        return res.json();
      })
      .then(json => {
        console.log('json is', json)
        setIsDialogLoading(false);
        setDatas(json.followings)
      })
      .catch(err => console.log(err))
  }

  const handleFollowerClick = person => {
    setIsOpen(false);
    setDatas([]);
    dispatch({type: 'SET_ACTIVITY', personName: person.username});
  }

  const handleFollowingClick = person => {
    setIsOpen(false);
    setDatas([]);
    dispatch({type: 'SET_ACTIVITY', personName: person.username});
  }

  const handleRemoveFollower = person => {
    fetch(root + `/auth/relation?type=REMOVE_FOLLOWER&person_id=${person._id}`, { method: 'post', credentials: 'include' })
    .then(res => {
      return res.json();
    })
    .then(json => {
      console.log('json is', json)
      const newDatas = datas.filter(data => data.person._id !== person._id)
      setDatas(newDatas);
    })
    .catch(err => console.log(err))}

  const handleUnfollow = person => {
    fetch(root + `/auth/relation?type=UNFOLLOW&person_id=${person._id}`, { method: 'post', credentials: 'include' })
    .then(res => {
      return res.json();
    })
    .then(json => {
      console.log('json is', json)
      const newDatas = datas.filter(data => data.person._id !== person._id)
      setDatas(newDatas);
    })
    .catch(err => console.log(err))}

  const handleClose = () => {
    setIsOpen(false);
  }

  const handleDeleteCanvasMapping = canvasMapping => {
    const okay = window.confirm(
      'Are you sure to delete this canvas?'
    )
    if(okay){
      fetch(`${root}/api/canvas?canvasName=${canvasMapping.name}`, 
        { 
          method: 'delete', 
          credentials: 'include' 
        }
      )
        .then(res => res.json())
        .then(json => {
          console.log('json is', json);
          dispatch({type: 'UPDATE_CANVAS_MAPPINGS', name: canvasMapping.name})
        })
    }
  }

  const handleOptionClick = (e, option) => {
    const newHashtag = { name: option.name, color: option.color, loweredName: option.name.toLowerCase()};
    fetch(root + `/api/user/tag`, 
      { 
        method: 'post', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({...newHashtag}), 
        credentials: 'include' 
      }
    )
      .then(res => {
        return res.json();
      })
      .then(json => {
        console.log('json is', json)
        dispatch({type: 'ADD_USER_TAG_$', hashtag: newHashtag})
      })
      .catch(err => console.log(err))
  }


  const zoomGrid = (scrollRef) => {
    const currentZoom = parseFloat(scrollRef.current.style.zoom || 1);
    const newZoom = currentZoom + 0.25;
    if (newZoom > 2.5) return;
  
    // Calculate the center point of the viewport
    const rect = scrollRef.current.getBoundingClientRect();
    const centerX = (rect.left + rect.right) / 2;
    const centerY = (rect.top + rect.bottom) / 2;
  
    // Calculate the scroll offset
    const offsetX = (centerX - window.scrollX) * (newZoom / currentZoom - 1);
    const offsetY = (centerY - window.scrollY) * (newZoom / currentZoom - 1);
  
    // Apply the new zoom level
    scrollRef.current.style.zoom = `${newZoom}`;
  
    // Adjust the scroll position to keep the center point in view
    window.scrollTo(window.scrollX + offsetX, window.scrollY + offsetY);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        console.log('Scrolled more than 200px from top');
        setIsScrolledDown(true);
      } else {
        setIsScrolledDown(false);
      }
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if(appType == 'WEB'){
      if(params.keyword){
        loadCanvas(params.person_name, params.keyword);
      } else {
        loadPerson(params.person_name);
      }
    }
  }, [params]);

  useEffect(() => {
    const handleScroll = () => {
    // @var int totalPageHeight
      var totalPageHeight = document.body.scrollHeight; 

      // @var int scrollPoint
      var scrollPoint = window.scrollY + window.innerHeight;

      // check if we hit the bottom of the page
      if(scrollPoint >= totalPageHeight&&hasMore){
        console.log("at the bottom");
        handleLoadMore();
      }
    };

    console.log('useRef in useEffect', scrollRef)
    const container = scrollRef.current;
    if(!container) return;
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activities]);

  if(personStore.loading){
    return(
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', jusitfyContent: 'center'}}>
        <Skeleton width={400} height={600}/>
      </div>
    )
  }

  if(!person) return null;
  if(!user){
    return (
      <div>
        <div align='center' style={{marginTop: '100px'}}>
          <SignIn/>
        </div>
        <div style={{textAlign: 'center', backgroundColor: '#fafafa', marginTop: '50px'}}>
          <div style={{display: 'flex', paddingTop: '50px'}}>
            <Avatar alt="" src={person.profileImageUrl}/>
            <Typography style={{margin: '20px'}}>@{person.username}</Typography>
          </div>
          <div style={{marginBottom: '20px'}}>
            <Button color='secondary' sx={{borderRadius: '20px'}}>{person.followingCount} following</Button>
            <Button color='secondary' sx={{borderRadius: '20px'}}>{person.followerCount} followers</Button>
          </div>
          <div style={{margin: '20px'}}>
            <p>{person.description}</p>
          </div>
        </div>
      </div>
    )
  }

  const isFollowed = person.followStatus[0]?.status == 'FOLLOW';
  const isFriend = person.followStatus[0]?.status == 'FRIEND';
  const isOwner = person._id == user._id;

  let SimpleActivities = activities.map(activity => { 
    const isReaction = Boolean(activity.type); 
    const isComment = Boolean(activity.writerIds);
    let isWriter, isTagged;
    if(isComment){
      const commonWriterId = activity.writerId === person._id;
      const commonPersontag = activity.persontags.filter(persontag => persontag._id === person._id)
      if(commonWriterId.length>0){
        isWriter = true;
      }
      if(commonPersontag.length>0){
        isTagged = true;
      }
    }

    //seperation value is 200px per 1 span. round down to the nearest integer. for example, 300px will be 2 spans.
    let spanValue = Math.ceil(activity.action.highlightData.elementWidth/100/4);
    if(spanValue<2) spanValue = 2;
    // if (spanValue % 2 !== 0) {
    //   spanValue += 1; // Ensure spanValue is an even number
    // }
    if(spanValue>6) spanValue = 6;

    
    return(
      // <Paper style={{borderRadius: '20px', margin: '10px', width: activity.action.highlightData.elementWidth/2}}>
        <Paper style={{borderRadius: '20px', margin: '10px', gridColumn: `span ${spanValue}`}}>

          <div style={{ display: 'flex', flexDirection: 'row'}}>
            {
            /* <span style={{display: 'flex', marginLeft: '5px'}}>
              <Typography noWrap>@{person.username}</Typography>
            </span>
            {
              activity.action.persontags.length>0
              ?
                <span style={{display: 'flex', marginLeft: '5px'}}>
                  <Typography noWrap>messaged on</Typography>
                </span>
              :
                <span style={{display: 'flex', marginLeft: '5px'}}>
                  <Typography noWrap>bookmarked</Typography>
                </span>

            }
            {(() => {
              switch(activity.type) {
                case 'COMMENT':
                  if(activity.action.highlightData){
                    const { highlightData } = activity.action;
                    if(activity.action.imgUrl){
                      if(activity.action.highlightData.innerText){
                        return(
                          <span style={{display: 'flex', marginLeft: '5px'}}>
                            <Typography noWrap>this img and text</Typography>
                          </span>
                        )
                      } else {
                        return(
                          <span style={{display: 'flex', marginLeft: '5px'}}>
                            <Typography noWrap>this img</Typography>
                          </span>
                        )
                      }
                    } else {
                      return(
                        <span style={{display: 'flex', marginLeft: '5px'}}>
                          <Typography noWrap>this text</Typography>
                        </span>
                      )
                    }
                  } else {
                    return(
                      <span style={{display: 'flex', marginLeft: '5px'}}>
                        <Typography noWrap>a position</Typography>
                      </span>
                    )
                  }
                case 'LIKE_COMMENT':
                  return(
                    <span style={{display: 'flex', marginLeft: '5px'}}>
                      <Typography noWrap>liked the comment</Typography>
                    </span>
                  )
                case 'REPLY':
                  return(
                    <span style={{display: 'flex', marginLeft: '5px'}}>
                      <Typography noWrap>replied to this comment</Typography>
                    </span>
                  )
                default:
                  return null
              }
            })()} */}
            {
              /*
              isReaction?
                <span style={{display: 'flex', marginLeft: '5px'}}>
                  <Favorite style={{color: 'red'}}/>
                  <Typography noWrap>liked</Typography>
                </span>
              :
                null
            }
            {
              isComment?
                <span style={{display: 'flex', marginLeft: '5px'}}>
                  <Typography noWrap>shared</Typography>
                </span>
              :
                null
            }
            {
              isReaction&&isWriter?
                <span style={{display: 'flex', marginLeft: '5px'}}>
                  <Typography noWrap>&</Typography>
                </span>
              :
                null
            }
            {

              //when it is comment
              isComment&&activity.isPublic
              ?
                <span style={{display: 'flex', marginLeft: '5px'}}>
                  <ChatBubble/>
                  <Typography noWrap>commented publicly on</Typography>
                </span>
              :
              isComment&&!activity.isPublic
              ?
                <span style={{display: 'flex', marginLeft: '5px'}}>
                  <ChatBubble/>
                  <Typography noWrap>commented privately on</Typography>
                </span>
              :
                null
            }
            {
              isWriter?
                <span style={{display: 'flex', marginLeft: '5px'}}>
                  <ChatBubble/>
                  <Typography noWrap>commented on</Typography>
                </span>
              :
                null
            }
            {
              isWriter&&isTagged?
                <span style={{display: 'flex', marginLeft: '5px'}}>
                  <Typography noWrap>&</Typography>
                </span>
              :
                null
            }
            {
              isTagged?
                <span style={{display: 'flex', marginLeft: '5px'}}>
                  <Typography noWrap>is tagged by {activity.writer[0].username} on</Typography>
                </span>
              :
                null

            <span style={{display: 'flex', marginLeft: '5px'}}>
              <Typography noWrap>
                {moment(activity.action.createdAt).fromNow()}
              </Typography>
            </span>
              */

            }
          </div>
        {
          /*
            isOwner?
              <Tooltip title='Hide'>
                <IconButton onClick={handleDoneClick} >
                  <Done fontSize='small'/>
                </IconButton>
              </Tooltip>
            :
              null
          */
        }
        {
          activity.content[0]?
            <SimpleContent type={personStore.keyword? 'KEYWORD_FEED' : 'FEED'} isBookmarkLayout={isBookmarkLayout} activity={activity}/>
          :
            null
        }
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', margin: '10px'}}/>
      </Paper>
    )
  })

  const EllipsisText = props => {
    const { children } = props

    return (
      <div style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 100,
      }}>
        {children}
      </div>
    )
  }

  const ZoomIcons = () => {
    return (
      <>
    <IconButton size='large' onClick={()=>{

      const currentZoom = parseFloat(scrollRef.current.style.zoom || 1);
      const newZoom = currentZoom + 0.25;
      if (newZoom > 2.5) return;

      scrollRef.current.style.zoom = `${newZoom}`;
      scrollRef.current.style.width = `${window.innerWidth / newZoom}px`;

      if(!isScrolledDown) return;

      // // Calculate the center point of the viewport
      // const rect = scrollRef.current.getBoundingClientRect();
      // const centerX = (rect.left + rect.right) / 2;
      // const centerY = (rect.top + rect.bottom) / 2;

      // // Calculate the scroll offset
      // const offsetX = (centerX - window.scrollX) * (newZoom / currentZoom - 1);
      // const offsetY = (centerY - window.scrollY) * (newZoom / currentZoom - 1);

      // // Apply the new zoom level

      // // Adjust the scroll position to keep the center point in view
      // window.scrollTo(window.scrollX + offsetX, window.scrollY + offsetY);


      }} 
    >
      <AddIcon/>
    </IconButton>
    <IconButton size='large' onClick={()=>{
        const currentZoom = parseFloat(scrollRef.current.style.zoom || 1);
        const newZoom = currentZoom - 0.25;
        if(newZoom<0.2) return;

        scrollRef.current.style.zoom = `${currentZoom - 0.25}`;
        scrollRef.current.style.width = `${window.innerWidth / newZoom}px`;

      }} 
    >
      <RemoveIcon/>
    </IconButton>
    {
      personStore.keyword
      ?
        <IconButton size='large' onClick={()=>{
            navigate(location.pathname + '/canvas')
          }}
        >   
          <FileOpenIcon/>
        </IconButton>
      :
        null
    }
    </>
    )

  }

	return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', jusitfyContent: 'center', backgroundColor: '#fafafa', marginTop: '50px'}}>
          {
            !extensionInstalled
            ?
              <Extension/>
            :
              null
          }
          {
            isScrolledDown
            ?
              <div style={{ alignSelf: 'center', position: 'fixed', top: 0, left: 0, top: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 2147480000,
                left: '50%',
                transform: 'translateX(-50%)',
                // pointerEvents: 'none',
                /* Optionally, you can add more styling here */
              }}>
                <ZoomIcons/>
              </div>
            :
              null
          }
          <div align='center'>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px'}}>
              <Avatar src={person.profileImageUrl}/>
              <Typography style={{margin: '20px'}}>@{person.username}</Typography>
            </div>
            {
              isOwner
              ?
                null
              :
                <ToggleButton
                  //style={{color: contrast}}
                  color='secondary'
                  value="check"
                  selected={isFollowed||isFriend}
                  onChange={() => {
                    if(!user) return dispatch({ type: 'OPEN_SIGN_$' });
                    if(!isFollowed&&!isFriend){
                      dispatch({type: 'FOLLOW_PERSON', path: `/api/follow?person_id=${person._id}`})
                    } else {
                      dispatch({type: 'UNFOLLOW_PERSON', path: `/api/unfollow?person_id=${personStore.person._id}`})
                    } 
                  }}
                >
                  { !isFollowed&&!isFriend ? 'Add' : 'Undo' }
                </ToggleButton>
            }
          </div>
          <div style={{marginBottom: '20px'}}>
            <Button onClick={handleLoadFollowings} variant='outlined' color='primary' sx={{borderRadius: '20px'}}>{person.followingCount} following</Button>
            <Button onClick={handleLoadFollowers} variant='outlined' color='primary' sx={{borderRadius: '20px'}}>{person.followerCount} followers</Button>
          </div>
          <div>
            <p>{person.description}</p>
          </div>
          {
            isOwner&&user.subscriptionStatus == 'DONOR'
            ?
              <Autocomplete
                style={{pointerEvents: 'auto'}}
                id="country-select-demo"
                sx={{ width: 200 }}
                options={userCanvasMapping.canvasMappings}
                getOptionLabel={(option) => option.name}
                open={isOptionOpen}
                onOpen={() => setIsOptionOpen(true)}
                onClose={() => setIsOptionOpen(false)}
                renderOption={(props, option) => (
                  <>
                    <ListItem
                      key={option.name}
                      sx={{ cursor: 'pointer' }}
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCanvasMapping(option)}>
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <Chip
                        color='primary'
                        style={{
                          backgroundColor: option.color,
                        }}
                        label={<EllipsisText>{'#' + option.name}</EllipsisText>}
                        onClick={e => handleOptionClick(e, option)}
                      />
                    </ListItem>
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search your canvas"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            :
              null
          }
          <Chips person={person} isOwner={isOwner} setIsBookmarkLayout={setIsBookmarkLayout}/>

          {/* {
            isOwner
            ?
            <div>
              <IconButton size='large' onClick={()=>{
                  changeLayout();
                }} 
              >
                <Bookmark />
              </IconButton>
              <IconButton size='large' onClick={()=>{
                  navigate('/settings')
                }} 
              >
                <SettingsIcon />
              </IconButton>
            </div>
            :
              null
          } */}
          <div>
            <ZoomIcons/>
          </div>
          
          {
            type=='PUBLIC'&&!isFriend
          ?
            <div>
              <Typography>Follow this person to get newsfeeds</Typography>
            </div>
          :
            null
          }
            <div>
                {
                  !isBookmarkLayout
                  ?
                    // <div style={{width: '100%', margin: 'auto'}} ref={scrollRef}>
                    //   <Masonry gutter='20px' columns={1} spacing={3}>
                    //     {SimpleActivities}
                    //   </Masonry>
                    // </div>
                    null
                  :
                    <div align='center' ref={scrollRef} style={{
                      // gap: '10px', 
                      display: 'grid',
                      width: '100vw',
                      gridTemplateColumns: 'repeat(auto-fill, minmax(130px, 1fr))',
                      gridAutoFlow: 'dense',
                      justifyContent: 'center',
                    }}>
                      {SimpleActivities}
                      {/* <Masonry gutter='20px' columns={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 4 }} spacing={3}>
                        {SimpleActivities}
                      </Masonry> */}
                    </div>
                }
            </div>
         <Dialog onClose={handleClose} open={isOpen}>
          <DialogTitle>{dataType}</DialogTitle>
          {
            isDialogLoading?
              <Skeleton />
            :
              <List sx={{ pt: 0 }}>
                {datas.map(data => {
                  const personStore = data.person;
                  if(dataType==='Followers') 
                    return (
                      <ListItem button onClick={()=>handleFollowerClick(personStore)}>
                        <ListItemAvatar>
                          <Avatar src={personStore.profileImageUrl}/>
                        </ListItemAvatar>
                        <ListItemText primary={personStore.username} />
                        {
                          isOwner
                          ?
                            <ListItemSecondaryAction style={{paddingLeft: '50px'}}>
                              <IconButton size='small' onClick={()=>handleRemoveFollower(personStore)}>
                                <Clear fontSize='small'/>
                              </IconButton>
                            </ListItemSecondaryAction>
                          :
                            null
                        }
                      </ListItem>
                    )

                    return (
                      <ListItem button onClick={()=>handleFollowingClick(personStore)}>
                        <ListItemAvatar>
                          <Avatar src={personStore.profileImageUrl}/>
                        </ListItemAvatar>
                        <ListItemText primary={personStore.username} />
                        {
                          isOwner
                          ?
                            <ListItemSecondaryAction style={{paddingLeft: '50px'}}>
                              <IconButton size='small' onClick={()=>handleUnfollow(personStore)}>
                                <Clear fontSize='small'/>
                              </IconButton>
                            </ListItemSecondaryAction>
                          :
                            null
                        }
                      </ListItem>
                    )
                })
                }
              </List>
          }

        </Dialog> 
  </div>
    );
}

export default Activity;
